<template>
  <div class="penddingManage">
    <div class="facilityBox">
      <el-tabs v-model="activeCmpt" @tab-click="cmptChange">
        <el-tab-pane label="已导入" name="1"></el-tab-pane>
        <el-tab-pane label="正在申请" name="2"></el-tab-pane>
        <el-tab-pane label="确认付款" name="3"></el-tab-pane>
        <el-tab-pane label="核对发票" name="4"></el-tab-pane>
        <el-tab-pane label="平台核对发票" name="5"></el-tab-pane>
        <el-tab-pane label="临登审核" name="6"></el-tab-pane>
      </el-tabs>
      <Imported v-if="activeCmpt == 1"></Imported>
      <Wait v-if="activeCmpt == 2"></Wait>
      <Payment v-if="activeCmpt == 3"></Payment>
      <Agree v-if="activeCmpt == 4"></Agree>
      <PlatformCheck v-if="activeCmpt == 5"></PlatformCheck>
      <DriverTaxRegCheck v-if="activeCmpt == 6"></DriverTaxRegCheck>
    </div>
  </div>
</template>

<script>
import Imported from './cmpt/imported'
import Agree from './cmpt/agree'
import Payment from './cmpt/payment'
import Wait from './cmpt/wait'
import PlatformCheck from './cmpt/platformCheck'
import DriverTaxRegCheck from './cmpt/driverTaxRegCheck'
export default {
  data() {
    return {
      activeCmpt: '1', //当前激活哪个tabs页
    }
  },
  methods: {
    //tabs页面跳转
    cmptChange(e) {
      this.activeCmpt = e.name
    },

  },
  components: {
    Imported,
    Agree,
    Payment,
    Wait,
    PlatformCheck,
    DriverTaxRegCheck
  }
}
</script>

<style lang="scss" scoped>
.penddingManage {
  .facilityBox {}
}
</style>